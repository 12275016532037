<template>
    <ContentWrapper>
        <breadcrumb name="Storage"/>
        <div class="container animated-fast fadeInUp">

            <b-row>
                <b-col>

                  <balance-cannabis class="animated-fast fadeInDown" v-if="storage" :balance="storage.balance"></balance-cannabis>

                </b-col>
                <b-col>
                    <div v-if="storage" class="card card-default card-stat animated-fast fadeInDown">
                        <div class="card-header">
                          {{$t('growing.view.storage.amount')}} <span>(total)</span><i class="card-header-icon fad fa-boxes-alt"></i>
                        </div>
                        <div class="card-body py-0">
                            <formatted-number :amount="storage.harvested - storage.balance.locked" type="cannabis" animated=""/>
                        </div>
                        <div class="card-footer p-0">
                            <b-btn v-if="$route.name !== 'Growing:Plants'" :to="{name: 'Growing:Plants'}" variant="link"
                                   class="float-right bg-white px-1 py-0 m-1"><i class="fad fa-seedling fa-2x"></i>
                            </b-btn>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <deliveries v-if="storage" class="animated-fast fadeInUp" :storage="storage"/>

            <div v-if="!storage" :class="loading ? 'whirl' : ''"></div>

            <b-row v-if="storage">
                <b-col sm="12" v-if="storage.groups.length === 0 && !loading">
                    <div class="m-3 alert alert-info">
                        {{ $t('growing.component.storage.empty') }}
                    </div>
                </b-col>
                <b-col sm="12" v-for="(item, index) in storage.groups"
                       :key="index">
                    <div class="card card-default bg-white animated-fast fadeInLeft" v-if="item.locked_amount === 0">
                        <div class="card-header">{{$t('growing.view.storage.storage.label')}} #{{item.label}} {{ item.locked_amount > 0 ? 'locked' : ''}} </div>
                        <div class="card-body rounded-0">
                            <b-row class="align-items-center">
                                <b-col md="3" class="text-left border-right">
                                  <div v-if="item.locked_amount > 0"><span class="text-lg">{{item.locked_amount | cannabis}}g</span> <span>locked</span></div>
                                    <div v-else="item.locked_amount > 0" class="text-lg">{{item.amount | cannabis}}g</div>
                                    <p class="m-0">{{item.strain.name}}</p>
                                </b-col>
                                <b-col md="9" v-if="item.locked_amount === 0">
                                    <b>{{$t('growing.view.storage.transactions.last')}}</b>
                                    <router-link tag="a" class="ml-2" :to="{name: 'Wallet'}">{{$t('growing.view.storage.transactions.all')}}</router-link>
                                    <hr/>
                                    <div class="row" v-for="transaction in item.transactions"
                                         :key="transaction.label">
                                        <div class="col-2">{{ transaction.label }}</div>
                                        <div class="col-4">{{ transaction.created_at | moment('utc', 'L')}}
                                            <span class="d-inline-block" style="width: 7em">{{ transaction.created_at | moment('utc', 'LTS')}}</span>
                                        </div>
                                        <div class="col-4">{{ $t('wallet.component.transactions.types.' + transaction.type + '.title') }}
                                        </div>
                                        <div class="text-right col">
                                            <span class="text-danger" v-if="!transaction.positive">-{{ transaction.amount | cannabis }}g</span>
                                            <span class="text-primary" v-if="transaction.positive">{{ transaction.amount | cannabis }}g</span>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="card-footer bg-gray-dark bt0 clearfix btn-block d-flex" v-if="item.locked_amount === 0">
                            <div class="ml-auto">
                                <b-btn :to="{name: 'Growing:Grow', params: {label: item.label}}" variant="primary" href=""
                                       class="mr-2 mb-2"><i class="fad fa-info-circle mr-1"></i>
                                    {{$t('growing.view.storage.grow.details')}}
                                </b-btn>
                                <b-btn @click="sell(item.amount, item)"
                                       variant="primary" href="" class="mr-2 mb-2"><i class="fad fa-sack-dollar mr-1"></i>
                                    {{$t('growing.view.storage.grow.sell')}}
                                </b-btn>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>

        </div>
    </ContentWrapper>
</template>
<style>

.card.locked {
  opacity: 0.7 !important;
}

</style>
<script>

    import Deliveries from "../../components/Storage/Deliveries";
    import 'flag-icon-css/css/flag-icons.min.css';
    import BalanceCannabis from "../../../Wallet/components/BalanceCannabis";

    export default {
        components: {BalanceCannabis, Deliveries},
        data() {
            return {
                storage: null,
                loading: true
            }
        },
        mounted() {
            this.$api.get('growing/storage').then(response => {
                this.storage = response.data;
                this.loading = false;
            }).catch((error) => {
                if (error.status === 401) {
                    this.$swal.fire({
                        title: 'No plants found!',
                        text: 'You need at least one plant to get access to this page!',
                        type: 'warning'
                    });
                    this.$router.push({name: 'Growing:Sale'})
                }
            });
        },
        methods: {
            sell(amount, grow) {

                let address = null;

                this.$api.get('user/address').then((response) => {
                    address = response.data;
                });

                this.$swal.fire({
                    title: 'Amount of CBD',
                    html:
                        'Select <b>amount</b> you want to <b>sell</b> in gramm',
                    input: 'range',
                    showCancelButton: true,
                    inputAttributes: {
                        min: 0.01,
                        max: amount,
                        step: 0.01
                    },
                    inputValue: amount
                }).then((response) => {
                    if (!response.dismiss) {
                        this.$swal.fire({
                            title: 'Terms and conditions',
                            input: 'checkbox',
                            showCancelButton: true,
                            html:
                            '<pre class="text-left mx-auto mt-3 b p-3">' + address.firstname + ' ' + address.lastname + '<br/>' + address.street + ' ' + address.house_number + '<br/>' + address.zip + ' ' + address.town + '<br/>' + address.country + '</pre> going to sell <b>' + response.value + 'g ' + grow.strain.name + '</b> for <b>' + Math.round(response.value * grow.offer.price * 100) / 100 + ' EUR</b> to <pre class="text-left mx-auto mt-3 b p-3">Cannerald AG<br/>Kirchgasse 30<br/>3312 Fraubrunnen<br/>Switzerland</pre>',
                            inputValue: 1,
                            inputPlaceholder:
                                'I agree with the <a href="https://cannergrow.com/terms/conditions-harvest-offer">terms and conditions</a>',
                            confirmButtonText:
                                'Sell Now',
                            inputValidator: (result) => {
                                return !result && 'You need to agree with T&C'
                            }
                        }).then((response_terms) => {
                            if (!response_terms.dismiss) {
                                this.$auth.requestVerfificationCode().then(code => {
                                    this.$api.post('growing/storage/sell', {
                                        'amount': response.value,
                                        'label': grow.label,
                                        verification_code: code.verification_code,
                                    }).then(() => {
                                        this.$swal.fire('Success', 'You will get a notification after the offer has been confirmed and executed', 'success');
                                    }).catch((error) => {
                                        if (error.status === 422) {
                                            this.$swal.fire('Error', error.data.message, 'error');
                                        }
                                    });
                                });
                            }
                        });
                    }
                });
            }
        }
    }
</script>
