<template>
    <div class="card card-data-browser card-default">
        <div class="card-header">
            <i class="card-title-icon" :class="icon"></i> {{ $t('growing.component.storage.deliveries.heading') }}
        </div>

        <div class="card-body" v-if="editAddress">
          <address-form entity-slot="delivery" @address-filled="editAddress = false"/>
        </div>
        <div class="card-body p-0" v-else>

          <div class="my-2">
            <button @click="deliver()" href="" class="btn btn-primary ml-2 mb-2"><i class="fad fa-truck-loading mr-1"></i>
              {{ $t('growing.component.storage.deliveries.btn_deliver') }}
              <span class="flag-icon flag-icon-eu ml-1 mr-1"></span>
              <span class="flag-icon flag-icon-ch"></span>
            </button>
            <button @click="editAddress = true" class="btn btn-primary ml-2 mb-2"><i class="fad fa-address-card mr-1"></i>
              {{ $t('growing.component.storage.deliveries.btn_change_address') }}
            </button>
            <router-link :to="{name: 'Shop:Sales'}" class="btn btn-primary ml-2 mb-2"><i class="fas fa-shopping-cart mr-1"></i>
              {{ $t('growing.component.storage.deliveries.btn_buy_products') }}
            </router-link>
          </div>

          <div v-show="state.data.length" class="table-wrapper">
            <div class="table-responsive text-nowrap">
              <table class="table table-striped table-bordered">
                <thead>
                <tr>
                  <th>{{ $t('growing.component.storage.deliveries.created_at') }}</th>
                  <th>{{ $t('growing.component.storage.deliveries.amount') }}</th>
                  <th>{{ $t('growing.component.storage.deliveries.shipped_at') }}</th>
                  <th>{{ $t('growing.component.storage.deliveries.tracking_code') }}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in state.data" :key="item.id">
                  <td>{{ item.created_at }}</td>
                  <td class="text-right">{{ item.amount }}g</td>
                  <td v-if="item.refunded">
                    {{ $t('growing.component.storage.deliveries.refunded') }}
                  </td>
                  <td v-else-if="item.shipped_at">{{ item.shipped_at }}</td>
                  <td v-else>
                    {{ $t('growing.component.storage.deliveries.waiting_for_shipment') }}
                  </td>
                  <td><a :href="item.tracking_code_url" target="_blank" rel="noreferrer">{{
                      item.tracking_code
                    }}</a></td>
                  <td class="p-0 pt-1 text-center">
                    <router-link :to="{name: 'Growing:Storage:Delivery', params: {id: item.id}}" variant="link"><i
                        class="btn btn-xs fad fa-eye text-secondary" style="font-size:1.3em"></i>
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-show="!state.data.length && !loading">
            <div class="m-3 alert alert-info">
              {{ $t('growing.component.storage.deliveries.empty') }}
            </div>
          </div>
        </div>
    </div>
</template>

<script>

import AddressForm from "@/components/Forms/AddressForm.vue";

export default {
    props: ['preSelectType', 'storage'],
    components: { AddressForm },
    data() {
        return {
            endpoint: '/growing/storage/deliveries',
            endpointParams: {},
            state: {
                data: [],
            },
            loading: true,
            members: [],
            pages: 0,
            icon: 'fad fa-truck-loading',
            editAddress: false
        }
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh: function () {
            this.loading = true;

            return this.$api.get(this.endpoint)
                .then(response => {
                    this.state.data = response.data;
                    this.loading = false;
                    return response;
                });
        },
        deliver() {
          this.$api.get('growing/grows/delivery-option').then(response => {
            let deliveryOption = response.data.option;
            let address = response.data.address;

            if (this.storage.available < deliveryOption.minimum) {
              this.$swal.fire('Required minimum not available for shipping in your balance. You need at least ' + deliveryOption.minimum + 'g for shipping.');
              return;
            }

            let maxValue = this.storage.balance.amount > response.data.specific_limit ? response.data.specific_limit : Math.floor(this.storage.balance.amount*10)/10;

            this.$swal.fire({
              title: this.$t('growing.component.storage.deliveries.popup_amount.title'),
              html: this.$t('growing.component.storage.deliveries.popup_amount.description', {limit: deliveryOption.limit}),
              input: 'range',
              showCancelButton: true,
              inputAttributes: {
                min: deliveryOption.minimum,
                max: maxValue,
                step: 0.1
              },
              inputValue: maxValue
            }).then((response) => {
              if (!response.dismiss) {
                this.confirmDelivery(deliveryOption, address, response.value);
              }
            });
          }).catch(() => {
            this.$swal.fire('Shipping this strain into your country is not available.');
          })
        },
        confirmDelivery(deliveryOption, address, amount) {
          this.$swal.fire({
            title: 'Confirm address',
            input: 'select',
            inputOptions: {
              shipping_provider: deliveryOption.shipping_provider + ' - ' + deliveryOption.shipping_price + ' EUR'
            },
            showCancelButton: true,
            html:
                '<pre class="text-left mx-auto mt-3 b p-3">' + address.firstname + ' ' + address.lastname + '<br/>' + address.street + ' ' + address.house_number + '<br/>' + address.zip + ' ' + address.town + '<br/>' + address.country + '</pre> You will get <b>' + this.$options.filters.cannabis(amount*deliveryOption.delivery_group.multiplier) + 'g CBD</b> delivered' + (deliveryOption.delivery_group.multiplier < 1 ? ' (' + amount + 'g from your harvest reduced to 0.2% THC and imported to EU, all fees included). This cannabis is only legal for further processing and is not recommended to smoke.' : '') + '<br/>Please select the delivery method, the costs will be subtracted from your balance.',
            confirmButtonText:
                'Request Delivery',
          }).then((response_terms) => {
            if (!response_terms.dismiss) {
              this.$auth.requestVerfificationCode().then(code => {
                this.$api.post('growing/storage/deliver', {
                  'amount': amount,
                  verification_code: code.verification_code,
                }).then(() => {
                  this.$swal.fire('Success', 'You will get updates if the status of your delivery changes', 'success');
                }).catch((error) => {
                  if (error.status === 422) {
                    this.$swal.fire('Error', error.data.message, 'error');
                  }
                });
              });
            }
          });
        },
    }
}
</script>